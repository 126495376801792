@import '../core/variables.scss';
@import '../core/breakpoints.scss';

.checkbox {
  margin: 0;
}

.formInputContainer {
  display: flex;
  justify-content: flex-start;
}

.inputField {
  width: 100% !important;
  min-width: 125px;
  margin-bottom: 0;

  &.hasCreativeButton {
    div[class*='control'] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0;
    }

    span {
      height: 100%;

      input {
        height: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;
      }
    }
  }

  &.hideBorder:not(:hover) input {
    background-color: transparent;
    border-color: transparent;
  }

  div[class*='_control_'] {
    min-height: 40px;
  }
}

.selectField {
  width: 100%;
  min-width: 175px;

  &.hasCreativeButton > div[class*='control'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
  }

  &.hideBorder {
    div[class*='control'] {
      &:not(:hover) {
        background-color: transparent;
        border-color: transparent;

        div[class*='dropdownIndicator'],
        div[class*='clearIcon'] {
          visibility: hidden;
        }
      }
    }
  }

  > div[class*='control'] {
    height: 100%;
  }
}

.addCreative {
  flex-shrink: 0;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.addButton {
  font-weight: 600;
  padding: 8px 12px;
  font-size: $button-font-size;
}

.dropdownNote {
  margin: 4px 0 0;
  color: $grey-600;
  font-size: $x-small-font-size;
}

.existingCreativesContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;

  .existingCreative {
    width: fit-content;
    position: relative;

    &:hover {
      .hoverBackground,
      .deleteButton {
        display: block;
      }
    }

    .creative {
      display: block;
      width: 120px;
      height: 120px;
      object-fit: contain;
    }

    .hoverBackground {
      display: none;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      opacity: 0.3;
    }

    .deleteButton {
      display: none;
      position: absolute;
      width: fit-content;
      left: 0;
      right: 0;
      margin: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.dateField {
  min-width: 150px;
  max-width: 210px;

  &.hideBorder {
    font-size: 1.4rem;
    line-height: 4rem;
    height: 40px;
    display: block;
    border: none !important;
    width: 100%;
    font-weight: normal;

    .dateInput {
      background: transparent !important;
    }

    button[class*='clearButton'] {
      visibility: hidden;
    }

    &:hover {
      background-color: $white;
      border: 1px solid $grey-400 !important;
      border-radius: 4px !important;

      .dateInput {
        background: $white url(../assets/icon-calendar-grey.svg) no-repeat
          calc(100% - 10px) 50% !important;
      }

      button[class*='clearButton'] {
        visibility: visible;
      }
    }
  }
}

.submitButton {
  width: fit-content;

  &.softDisableButton {
    background-color: $pink !important;
    border-color: $pink !important;
    color: $primary-color !important;
  }
}

.contactBiline {
  margin-left: auto;
  width: fit-content;
  height: fit-content;

  &::before {
    right: 70% !important;

    @include medium {
      right: 55% !important;
    }
  }

  &.vEvent::before {
    right: 70% !important;

    @include medium {
      right: 20% !important;
    }
  }
}

.dateWrapper {
  max-width: 225px;
}

.dateTimeCalendar {
  display: flex;

  div[class*='react-datepicker-time__header'] {
    font-size: 1.5rem;
    font-weight: 600;
  }
}
